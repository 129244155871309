import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"scrollable":"","max-width":"540px"},model:{value:(_vm.dialogLocal),callback:function ($$v) {_vm.dialogLocal=$$v},expression:"dialogLocal"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v("Patient Data")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1),(_vm.waiting)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VCardText,{attrs:{"id":"patientDataId"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c('PatientDataBioInfo'),_c('PatientDataContactInfo',{attrs:{"requireFields":false}})],1)]}}])})],1)],1)],1),(_vm.confirmLoseChanges)?_c('ConfirmationDialog',{attrs:{"params":_vm.confirmLoseChangesParams},on:{"confirmation-dialog-response":_vm.confirmLoseChangesHandler}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }