import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Concussion Settings")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.concussionSetting),callback:function ($$v) {_vm.concussionSetting=$$v},expression:"concussionSetting"}},[_c(VRadio,{attrs:{"label":"Ask Questions","value":"true","color":"black"}}),_c(VRadio,{attrs:{"label":"Don't Ask Questions","value":"false","color":"black"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }