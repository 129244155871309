import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VToolbarTitle,[_vm._v("Exports")]),_c(VIcon,{staticClass:"ml-6",attrs:{"large":""},on:{"click":_vm.loadItems}},[_vm._v(" mdi-refresh ")])],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allResultData,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totalRecords,"loading":_vm.waiting,"footer-props":{'items-per-page-options':[15, 30, 50, 100]}},on:{"click:row":_vm.rowSelected,"current-items":_vm.currentItems,"update:options":[function($event){_vm.pagination=$event},_vm.loadItems]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"accent"}},on),[_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("Actions")])])]}}],null,true)},[_c(VList,_vm._l((_vm.actionItems),function(actionItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.testAction(index, item)}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"black"},domProps:{"textContent":_vm._s(actionItem.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(actionItem.title)}})],1)],1)}),1)],1)]}}])})],1)],1)],1),(_vm.confirmReportDelete)?_c('ConfirmationDialog',{attrs:{"params":_vm.confirmReportDeleteParams},on:{"confirmation-dialog-response":_vm.confirmReportDeleteHandler}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }