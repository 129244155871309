import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"scrollable":"","max-width":"1000px","persistent":_vm.notesChangedCounter > 0},model:{value:(_vm.dialogLocal),callback:function ($$v) {_vm.dialogLocal=$$v},expression:"dialogLocal"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"pr-5",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Close")])],1),_c(VCardText,{attrs:{"id":"resultDataId"}},_vm._l((_vm.tests),function(test){return _c('div',{key:test.eyes[0].visitId},[(test.eyes[0].errCode !== null && 0 === test.eyes[0].errCode)?_c('TestNotes',{attrs:{"mrn":test.mrn,"resultData":test.eyes[0],"resetData":_vm.resetData},on:{"save-note":_vm.saveNote,"note-changed":_vm.noteChangedFunc}}):_vm._e()],1)}),0)],1)],1),(_vm.confirmLoseChanges)?_c('ConfirmationDialog',{attrs:{"params":_vm.confirmLoseChangesParams},on:{"confirmation-dialog-response":_vm.confirmLoseChangesHandler}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }