import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Units of Measure")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.currentUserChanges.preferences.unitsOfMeasure),callback:function ($$v) {_vm.$set(_vm.currentUserChanges.preferences, "unitsOfMeasure", $$v)},expression:"currentUserChanges.preferences.unitsOfMeasure"}},[_c(VRadio,{attrs:{"label":"Metric","value":"METRIC","color":"black"}}),_c(VRadio,{attrs:{"label":"Standard","value":"STANDARD","color":"black"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }