import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c(VAlert,{attrs:{"dismissible":"","type":"success"},model:{value:(_vm.saveAlert),callback:function ($$v) {_vm.saveAlert=$$v},expression:"saveAlert"}},[_vm._v(" User profile updated. ")]),(_vm.waiting)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"xs":"12","sm":"6","md":"4"}},[_c(VCard,[_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1),_c(VCardText,[_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredName|minName:3","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Name","solo":"","error-messages":errors[0]},model:{value:(_vm.currentUser.displayName),callback:function ($$v) {_vm.$set(_vm.currentUser, "displayName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentUser.displayName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredEmail|email","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Email Address","solo":"","error-messages":errors[0]},model:{value:(_vm.currentUser.emailAddress),callback:function ($$v) {_vm.$set(_vm.currentUser, "emailAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentUser.emailAddress"}})]}}],null,true)}),_c('div',[_c('label',{staticClass:"checkbox"},[_c('ValidationProvider',{attrs:{"rules":"requiredAgree"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.healthCareProfessional),expression:"currentUser.healthCareProfessional"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.currentUser.healthCareProfessional)?_vm._i(_vm.currentUser.healthCareProfessional,null)>-1:(_vm.currentUser.healthCareProfessional)},on:{"change":function($event){var $$a=_vm.currentUser.healthCareProfessional,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.currentUser, "healthCareProfessional", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.currentUser, "healthCareProfessional", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.currentUser, "healthCareProfessional", $$c)}}}}),_c('span',{staticClass:"black--text pl-1"},[_vm._v("I agree that either I am a certified healthcare professional in the United States of America, otherwise Reflex data is for investigational and research purposes only, and should not be used for medical evaluation.")]),_c('br'),_c('span',{staticClass:"terms"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }