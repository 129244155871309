import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"scrollable":"","max-width":"540px"},model:{value:(_vm.dialogLocal),callback:function ($$v) {_vm.dialogLocal=$$v},expression:"dialogLocal"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v("Upload New Patient List")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogLocal = false}}},[_vm._v("Cancel")])],1),(_vm.waiting)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VCardText,[(!_vm.waiting)?_c('form',{attrs:{"enctype":"multipart/form-data","novalidate":""}},[_c('div',{staticClass:"dropbox"},[_c('input',{ref:"file",staticClass:"input-file",attrs:{"type":"file","id":"file","accept":".csv"},on:{"change":function($event){return _vm.save()}}}),(!_vm.waiting)?_c('p',[_vm._v(" Drag your patient CSV file here to begin"),_c('br'),_vm._v(" or click to browse. ")]):_vm._e()])]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }