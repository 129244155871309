import { render, staticRenderFns } from "./full-spectrum-result-fields.vue?vue&type=template&id=2e7af6a7&scoped=true&"
import script from "./full-spectrum-result-fields.vue?vue&type=script&lang=js&"
export * from "./full-spectrum-result-fields.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e7af6a7",
  null
  
)

export default component.exports