import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Contact Data")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":12}},[(_vm.requireFields)?_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"requiredEmail|email","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Email Address *","error-messages":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,false,1086207479)}):_c('ValidationProvider',{attrs:{"mode":"aggressive","rules":"email","debounce":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"Email Address","error-messages":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c(VCol,{attrs:{"cols":12}},[_c(VTextField,{attrs:{"label":"Phone Number"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }