import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":"870px"},model:{value:(_vm.dialogLocal),callback:function ($$v) {_vm.dialogLocal=$$v},expression:"dialogLocal"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v("Test Protocol Selection")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog(null)}}},[_vm._v("Cancel")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"xs":"12"}},[_c(VCard,{staticClass:"blue lighten-1 pa-1",attrs:{"tile":"","flat":"","height":598}},[_c(VCard,{attrs:{"tile":"","flat":"","height":590}},[_c(VCard,{attrs:{"tile":"","flat":""}},[_c(VDataTable,{staticStyle:{"max-height":"590px","overflow-y":"auto"},attrs:{"headers":_vm.headers,"items":_vm.testProtocolSuite.testProtocols,"item-key":"id","hide-default-footer":true},on:{"click:row":_vm.rowSelected}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }