import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v("Customizations")])]),_c(VCardActions,[_c(VBtn,{staticClass:"ml-3",attrs:{"color":"accent","loading":_vm.waiting,"disabled":_vm.waiting},on:{"click":_vm.save}},[_c(VIcon,{staticStyle:{"color":"#000000"},attrs:{"left":""}},[_vm._v("mdi-content-save")]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("Save")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mr-3",attrs:{"color":"accent","loading":_vm.waiting,"disabled":_vm.waiting},on:{"click":_vm.save}},[_c(VIcon,{staticStyle:{"color":"#000000"},attrs:{"left":""}},[_vm._v("mdi-content-save")]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("Save")])],1)],1),_c(VCardText,[_c('RecordingSettings'),_c('ConstrictResultFields'),_c(VContainer,{attrs:{"py-3":"","px-0":""}},[_c('FullSpectrumResultFields')],1),_c('NoLightResultFields')],1),_c(VCardText,[_c('ConcussionSettings'),_c('ExportType'),(_vm.currentUser.isProUser)?_c('OperationalMode'):_vm._e(),(_vm.currentUser.isProUser)?_c('UnitsOfMeasure'):_vm._e()],1),_c(VCardActions,[_c(VBtn,{staticClass:"ml-3 mb-5",attrs:{"color":"accent","loading":_vm.waiting,"disabled":_vm.waiting},on:{"click":_vm.save}},[_c(VIcon,{staticStyle:{"color":"#000000"},attrs:{"left":""}},[_vm._v("mdi-content-save")]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("Save")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mr-3 mb-5",attrs:{"color":"accent","loading":_vm.waiting,"disabled":_vm.waiting},on:{"click":_vm.save}},[_c(VIcon,{staticStyle:{"color":"#000000"},attrs:{"left":""}},[_vm._v("mdi-content-save")]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }