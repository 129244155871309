import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto my-12",attrs:{"max-width":"400"}},[_c('vue-plyr',{ref:"vuePlayer",attrs:{"options":_vm.playerOptions}},[_c('video',{attrs:{"src":_vm.videoUrl}})]),_c(VCardTitle,[_vm._v(_vm._s(_vm.header))]),_c(VCardText,[_c('div',{staticClass:"my-4 text-subtitle-1"},[_vm._v(_vm._s(_vm.subHeader))]),_c('div',[_vm._v(_vm._s(_vm.text))])]),(_vm.deepDiveUrl)?_c(VBtn,{staticClass:"ma-4",attrs:{"color":"accent"},on:{"click":_vm.deepDive}},[_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("Deep Dive")])]):_vm._e(),_c(VDivider,{staticClass:"mx-4"}),_c(VCardTitle,[_vm._v("Minimum Subscription Level")]),_c(VChip,{staticClass:"ma-2",attrs:{"color":"accent","text-color":"black"}},[_vm._v(_vm._s(_vm.subLevel))]),_c(VDivider,{staticClass:"mx-4"}),_c(VCardTitle,[_vm._v("Products")]),_vm._l((_vm.products),function(product){return [_c(VChip,{staticClass:"ma-2",attrs:{"color":"accent","text-color":"black"}},[_vm._v(_vm._s(product))])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }